


























import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { FormFornecedor, Fornecedor } from 	'@/models/Fornecedor'
import { FindFornecedorUseCase } from '@/usecases/fornecedor/FindFornecedorUseCase'
import axios, { CancelTokenSource } from 'axios'
import DialogoDeEdicaoDeFornecedor from '@/components/fornecedor/DialogoDeEdicaoDeFornecedor.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { aplicarMascaraParaCpfOculto, formatarCnpjOuCpf } from '@/shareds/formatadores'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import { DataOptions } from 'vuetify'
import { DeleteFornecedorUseCase } from '@/usecases'
import { TituloService } from '@/services'

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeEdicaoDeFornecedor,
	},
})
export default class TelaDeFornecedores extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeFornecedor

	headers = [
		{ text: 'Nome', value: 'razaoSocialOuNome' },
		{ text: 'CPF/CNPJ', value: 'cnpjOuCpfFormatado' },
		{ text: 'Estado', value: 'endereco.uf' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	carregando = false

	busca = ''

	editando = true

	fornecedores: Fornecedor[] = []

	findUseCase = new FindFornecedorUseCase()
	deleteUsecase = new DeleteFornecedorUseCase()
	cancelToken: CancelTokenSource | null = null
	findContasAPagar = new TituloService()

	created() {
		this.buscar()
	}

	criaFornecedor(): FormFornecedor {
		return {
			id: '',
			cnpjOuCpf: '',
			razaoSocialOuNome: '',
			inscricaoEstadual: 'ISENTO',
			inscricaoSuframa: '',
			email: '',
			telefones: [],
			endereco: {
				id: null,
				rua: '',
				numero: '',
				cep: '',
				bairro: '',
				complemento: '',
				codigoIBGE: '',
				cidade: '',
				uf: '',
				codigoPais: '',
				nomePais: '',
			},
			tipoFornecedor: 'Compra',
			contrato: null,
			marca: null,
		}
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				busca: this.busca || undefined,
				size: this.paginacao.itemsPerPage,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const page = await this.findUseCase.find(params, axiosConfig)

			this.totalRegistros = page.totalElements
			this.fornecedores = page.content
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async removerFornecedor(indice: number) {
		const fornecedor = this.itens[indice]
		if(!fornecedor.id) return

		try{
			const titulosPorFornecedor = await this.findContasAPagar.findTitulosPorFornecedor(fornecedor.id)
			
			if( titulosPorFornecedor.length > 1 || titulosPorFornecedor[0]){
				AlertModule.setError("Não é permitido excluir fornecedores que possuem titulos vinculados!")
			}else{
				await this.deleteUsecase.delete(fornecedor.id)
				this.fornecedores = this.fornecedores.filter(({ id }) => id !== fornecedor.id)
				AlertModule.setSuccess('Fornecedor removido com sucesso')
			}
		}catch(e){
			AlertModule.setError(e)
		}
	}

	get itens(){
		return this.fornecedores
	}

	get itensFormatados(): Display[] {
		return this.fornecedores.map(fornecedor => ({
			...fornecedor,
			cnpjOuCpfFormatado: fornecedor.cnpjOuCpf.length <= 11
				? aplicarMascaraParaCpfOculto(fornecedor.cnpjOuCpf)
				: formatarCnpjOuCpf(fornecedor.cnpjOuCpf),
		}))
	}

	abrirDialogoDeCriarFornecedor() {
		this.editando = false
		const fornecedor = this.criaFornecedor()
		this.dialogoDeEdicao.mostrar(fornecedor, true)
	}

	atualizarFornecedor(fornecedor: Fornecedor) {
		if (!fornecedor) return

		const indice = this.fornecedores.findIndex(
			fornecedorExistente => fornecedorExistente.id === fornecedor.id,
		)
		indice === -1
			? this.fornecedores.push(fornecedor)
			: this.fornecedores.splice(indice, 1, fornecedor)
		
		this.buscar()
	}

	abrirDialogoDeEditarFornecedor (indice: number ) {
		this.editando = true
		const fornecedor = this.fornecedores[indice]
		this.dialogoDeEdicao.mostrar(fornecedor, false)
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	@Watch('busca')
	onChangeBusca() {
		this.buscar()
	}
}

export interface Display extends Fornecedor {
	cnpjOuCpfFormatado: string
}
